import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import config from "../../../../config";
import Content from "../../../layouts/Content/Content";
import "./Notes.css";
import NotesWrapper from "../../../layouts/NotesWrapper";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth.js";

const Notes = () => {
    useAuth();
    
    const [notes, setNotes] = useState({});
    const [loading, setLoading] =  useState(true);
    const [searchParams] = useSearchParams();

    useEffect(() => {

        (async () => {
            const data = await getNotes();

            setNotes(data);

            setLoading(false);
        })();

    }, []);

    const getNotes = async () => {
        try {
            const subjectID = searchParams.get('id');
            const endpoint = `${config.rootEndpoint}/cuet-ug/notes/?id=${subjectID}`;
    
            const response = await axios.get(endpoint);
    
            return response.data;
            
        } catch (error) {
            return {};
        }
    }

    const createNotes = async(title, link, paid) => {
        try{
            const payload = {
                id: searchParams.get('id'),
                title,
                link,
                paid  
            }
  
            const url = `${config.rootEndpoint}/cuet-ug/notes/`;
            const token = localStorage.getItem('token');
            const options = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            await axios.post(url, payload, options);
            window.location.reload();
  
        }catch(error){
            const message = error.response?.data?.message || "Something went wrong";
            toast.error(message);
        }
    }
    
    const updateNotes = async (id, title, link, paid) => {
        try {
            const data = { id, title, link, paid };
  
            const endpoint = `${config.rootEndpoint}/cuet-ug/notes/`;
            const token = localStorage.getItem('token');
            const options = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            
            await axios.put(endpoint, data, options);
            window.location.reload();
  
        } catch (error) {
            const message = error.response?.data?.message || "Something went wrong";
            toast.error(message);
        }
    }

    const deleteNotes = async (id) => {
        try {
            const data = { id };
            const endpoint = `${config.rootEndpoint}/cuet-ug/notes/`;
            const token = localStorage.getItem('token');
  
            await axios.delete(endpoint, {
                data,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
  
            window.location.reload();
            
        } catch (error) {
            const message = error.response?.data?.message || "Something went wrong";
            toast.error(message);
        }
    }

    if(loading){
        return <div>Loading...</div>
    }

    else if(Object.keys(notes).length === 0){
        return <div>An error occured. Please try again later!</div>
    }
    

    return (
        <div className="notesUG">

            <div className="header">
                <h1>{searchParams.get('name')} Notes</h1>
            </div>

            <Content 
                contentDetails={notes.content}
                category='notes'
                type='cuet-ug'
            />

            <NotesWrapper
                notes={notes.notes}
                createNotes={createNotes}
                updateNotes={updateNotes}
                deleteNotes={deleteNotes}
            />

        </div>
    )
}

export default Notes;