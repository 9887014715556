import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import useGetRequest from "../../hooks/useGetRequest";
import config from "../../config.js";
import useAuth from "../../hooks/useAuth.js";
import EventCard from "../layouts/EventCard.jsx";
import EventModal from "../layouts/EventModal.jsx";

const Events = () => {
  useAuth();

  const [events, setEvents] = useState([]);
  const { sendGetRequest } = useGetRequest();

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await sendGetRequest(
        `${config.rootEndpoint}/events/all/`
      );
      setEvents(response.data);
    } catch (error) {
      toast.error("Failed to load events");
    }
  };

  const createEvent = async (eventDetails) => {
    try {
      const url = `${config.rootEndpoint}/events/`;
      const token = localStorage.getItem("token");
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(url, eventDetails, options);
      const newEvent = response.data;
      setEvents((prevEvents) => [...prevEvents, newEvent]);
      toast.success("Event created");
    } catch (error) {
      const message = error.response?.data?.message || "Something went wrong";
      toast.error(message);
      throw error;
    }
  };

  const deleteEvent = async (slug) => {
    const originalEvents = [...events];

    try {
      setEvents((prevEvents) =>
        prevEvents.filter((event) => event.slug !== slug)
      );
      const token = localStorage.getItem("token");
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.delete(
        `${config.rootEndpoint}/events/?slug=${slug}`,
        options
      );
    } catch (error) {
      const message = error.response?.data?.message || "Couldn't delete Event";
      toast.error(message);
      setEvents(originalEvents);
    }
  };

  return (
    <div
      style={{
        padding: "32px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <h1 style={{ textAlign: "center", marginTop: "16px" }}>Events</h1>

      <EventModal createEvent={createEvent} />

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        {events.length &&
          events.map((event) => (
            <EventCard
              key={event.event_id}
              event={event}
              deleteEvent={deleteEvent}
            />
          ))}
      </div>
    </div>
  );
};

export default Events;
