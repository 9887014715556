import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

import config from "../../../../config.js";
import "./Subjects.css";
import useAuth from "../../../../hooks/useAuth.js";

const SubjectCard = ({name, subjectID}) => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div onClick={() => setIsOpen(!isOpen)} className="subjectCard" id="card">
            <h3>{name}</h3>

            {isOpen && (
                <div className="categories">
                    <Link to = {`./notes?id=${subjectID}&name=${name}`}>Notes</Link>
                </div>
            )}
        </div>
    )
}

const Subjects = () => {
    useAuth();
    
    const[subjects, setSubjects] = useState([]);
    const[loading, setLoading] = useState(true);

    const getSubjects = async () => {
        try{
            const endpoint = `${config.rootEndpoint}/cuet-ug/subjects/`;
            const response = await axios.get(endpoint);

            return response.data;
        }catch(error){
            return [];
        }
    }

    useEffect(() => {

        (async () => {
            const data = await getSubjects();
            setSubjects(data);
            setLoading(false);
        })();

    }, []);

    if(loading){
        return <div>Loading...</div>
    }

    else if(subjects.length === 0){
        return <div>Couldn't fetch subjects. Please try again later!</div>
    }

  return (
    <div className="subjects">
        {subjects.map((item) => {
            return (
                <SubjectCard 
                    key = {item.subjectID}
                    name = {item.subjectName}
                    subjectID = {item.subjectID}
                />
            )
        })}
    </div>
  )
}

export default Subjects;
