import { useState } from "react";

const NotesEdit = ({ notesID, title, link, paid, updateNotes }) => {
  const [newTitle, setNewTitle] = useState(title);
  const [newLink, setNewLink] = useState(link);
  const [newPaid, setNewPaid] = useState(paid);

  return(
      <div className="form">
          <input type = "text" placeholder="Title" value={newTitle} onChange={(e) => setNewTitle(e.target.value)} />

          <input className="link" type = "text" placeholder="Link" value={newLink} onChange={(e) => setNewLink(e.target.value)}/>

          <select value={newPaid} onChange={(e) => setNewPaid(e.target.value)}>
              <option disabled>--PAID--</option>
              <option value="true">True</option>
              <option value="false">False</option>
          </select>

          <a style={{padding: "16px", borderRadius: "8px"}} className="btn-secondary" href={newLink} target="blank">View</a>

          <button 
            className="btn-primary" 
            onClick={() => updateNotes(notesID, newTitle, newLink, newPaid)}
          >
            Save
          </button>
      </div>
  )
}

const NotesView = ({ notesID, title, link, paid, setEditing, deleteNotes }) => {

  return (
      <div className="form">
          <input type = "text" value={title} disabled/>

          <input className="link" type = "text" value={link} disabled/>

          <select value={paid} disabled>
              <option value="true">True</option>
              <option value="false">False</option>
          </select>

          <a style={{padding: "16px", borderRadius: "8px"}} className="btn-secondary" href={link} target="blank">View</a>

          <button className="btn-secondary" onClick={() => setEditing(true)}>Edit</button>

          <button 
            className="btn-red" 
            onClick={() => deleteNotes(notesID)}
          >
            Delete
          </button>
          
      </div>
  )
}

const CurrentNotes = ({ notesID, title, link, paid, updateNotes, deleteNotes }) => {
  const [editing, setEditing] = useState(false);

  return(
      <>
          {editing ? (
              <NotesEdit
                  notesID={notesID}
                  setEditing={setEditing}
                  title={title}
                  link={link}
                  paid={paid}
                  updateNotes={updateNotes}
              />
          ) : (
              <NotesView
                  notesID={notesID}
                  setEditing={setEditing}
                  title={title}
                  link={link}
                  paid={paid}
                  deleteNotes={deleteNotes}
              />
          )}
      </>
  )
}

const CurrentNotesWrapper = ({ notes, updateNotes, deleteNotes }) => {
  return (
      <div className="notesLinksContainer element">
          {notes && (
              notes.map((item) => {
                  return (
                    <CurrentNotes 
                      key={item.notesID}
                      notesID={item.notesID}
                      title={item.title} 
                      link={item.link} 
                      paid={item.paid}
                      updateNotes={updateNotes}
                      deleteNotes={deleteNotes}
                    />
                  )
              })
          )}
      </div>
  )
}

const NewNotes = ({ createNotes  }) => {
  const [adding, setAdding] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newLink, setNewLink] = useState("");
  const [newPaid, setNewPaid] = useState("false");

  return(
      <>
          <h1 className="headline element">Add Notes</h1>

          {adding ? (
              <div className="notesLink form element">
                  <input type = "text" placeholder="Title" value={newTitle} onChange={(e) => setNewTitle(e.target.value)} />

                  <input className="link" type = "text" placeholder="Link" value={newLink} onChange={(e) => setNewLink(e.target.value)}/>

                  <select value={newPaid} onChange={(e) => setNewPaid(e.target.value)}>
                      <option disabled>--PAID--</option>
                      <option value="true">True</option>
                      <option value="false">False</option>
                  </select>

                  <a style={{padding: "16px", borderRadius: "8px"}} className="btn-secondary" href={newLink} target="blank">View</a>

                  <button 
                    className="btn-primary" 
                    onClick={() => createNotes(newTitle, newLink, newPaid)}
                  >
                    Create
                  </button>
              </div>

          ) : (
              <div className="element">
                  <button className="btn-secondary" onClick = {() => {setAdding(true)}}>Add New Notes</button>
              </div>
          )}
      </>
  )
}

const NotesWrapper = ({ notes, createNotes, updateNotes, deleteNotes }) => {
  return (
    <>
      <CurrentNotesWrapper 
        notes={notes}
        updateNotes={updateNotes}
        deleteNotes={deleteNotes}
      />

      <NewNotes
        createNotes={createNotes}
      />

      <hr></hr>
    </>
  )
}

export default NotesWrapper;