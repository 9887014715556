import { useContext, useEffect } from "react";
import { AuthContext } from "../components/layouts/AuthProvider";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
    const { isLoggedIn, loading } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if(!loading && !isLoggedIn)  navigate("/login");
    }, [isLoggedIn, loading]);
}

export default useAuth;