import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import axios from "axios";
import Content from "../../../layouts/Content/Content";
import config from "../../../../config";
import Header from "../../../layouts/Header";
import { toast } from "react-toastify";
import "./Pyq.css";
import PyqWrapper from "../../../layouts/PyqWrapper";
import useAuth from "../../../../hooks/useAuth.js";

const Pyq = () => {
    useAuth();
    
    const [pyq, setPyq] = useState({});
    const [searchParams] = useSearchParams();

    const getPyq = async () => {
        try{
            const id = searchParams.get('id');
            const sem = searchParams.get('sem');

            const endpoint = `${config.rootEndpoint}/ug/pyq/?id=${id}&sem=${sem}`
            
            const response = await axios.get(endpoint);

            return response.data;

        }catch(error){
            return {};
        }
    }

    const createPyq = async (year, paperLink, type) => {
        try {
            const data = {
                id: searchParams.get('id'),
                sem: searchParams.get('sem'),
                year,
                type,
                paperLink
            }

            const endpoint = `${config.rootEndpoint}/ug/pyq/`;
            const token = localStorage.getItem('token');
            const options = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }

            await axios.post(endpoint, data, options);
            window.location.reload();

        } catch (error) {
            const message = error.response?.data?.message || "Something went wrong";
            toast.error(message);
        }
    }

    const updatePyq = async (id, year, type, paperLink) => {
        try{
            const data = { id, year, type, paperLink };
            const token = localStorage.getItem('token');
            const options = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            } 

            await axios.put(`${config.rootEndpoint}/ug/pyq/`, data, options);
            window.location.reload();

        } catch(error){
            const message = error.response?.data?.message || "Something went wrong";
            toast.error(message);
        }
    }

    const deletePyq = async (id) => {
        try{
            const data = { id };
            const token = localStorage.getItem('token');

            await axios.delete(`${config.rootEndpoint}/ug/pyq/`, {
                data,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            window.location.reload();
    
        }catch(error){
            const message = error.response?.data?.message || "Something went wrong";
            toast.error(message);
        }
    }

    useEffect(() => {
        (async () => {
            const data = await getPyq();
            setPyq(data);
        })();
    }, []);

  return (
    <div className="notesPage">
        <Header heading={`${searchParams.get('discipline')} Pyqs`}/>

        <Stack spacing={6} sx={{padding: '32px'}}>
                <Content 
                    contentDetails={pyq.content}
                    category='pyq'
                    type='ug'
                />

                <PyqWrapper
                    pyq={pyq.pyq}
                    createPyq={createPyq}
                    updatePyq={updatePyq}
                    deletePyq={deletePyq}
                />
        </Stack>
    </div>
  )
}

export default Pyq;