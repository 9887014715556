const TextInput = ({
  name,
  type,
  label,
  placeholder,
  style,
  value,
  onChange,
  onBlur,
}) => {
  return (
    <>
      <label>{label}</label>
      <br />
      <input
        name={name}
        type={type || "text"}
        placeholder={placeholder}
        style={{ ...style, marginTop: "8px" }}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
};

export default TextInput;
