import { useState } from "react";
import { useEffect } from "react";
import useGetRequest from "../../hooks/useGetRequest";
import config from "../../config";
import useAuth from "../../hooks/useAuth";
import { useSearchParams } from "react-router-dom";
import EventDetails from "../layouts/EventDetails";

const EventDetailsWrapper = () => {
  useAuth();

  const [event, setEvent] = useState({});
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const { sendGetRequest, loading } = useGetRequest();

  useEffect(() => {
    fetchEventDetails();
  }, []);

  const fetchEventDetails = async () => {
    try {
      const eventId = searchParams.get("event");
      const url = `${config.rootEndpoint}/events/?id=${eventId}`;
      const response = await sendGetRequest(url);

      setEvent(response.data);
    } catch (error) {
      const errorMessage =
        error.response.status === 404
          ? "Event not found"
          : "Something went wrong";

      setError(errorMessage);
    }
  };

  if (loading) return <h1>Loading...</h1>;
  else if (error) return <h1>{error}</h1>;

  return <>{event && <EventDetails event={event} />}</>;
};

export default EventDetailsWrapper;
