import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from '@mui/material';
import "./CoursesUG.css";
import config from "../../../../config.js"
import CourseWrapper from "../../../layouts/CourseWrapper/CourseWrapper.jsx";
import DisciplineModal from "../../../layouts/DisciplineModal/DisciplineModal.jsx";
import useAuth from "../../../../hooks/useAuth.js";

const Courses = () => {
    useAuth();
    
    const [courses, setCourses] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    const getCourses = async () => {
        try{
            const endpoint = `${config.rootEndpoint}/ug/courses/`;
            
            const response = await axios.get(endpoint);

            return response.data;
        }catch(error){
            return [];
        }
    }

    useEffect(() => {
        (async () => {
            const data = await getCourses();

            setCourses(data);
        })()
    }, []);


    return (
        <>
            <Button style={{backgroundColor: 'cadetBlue', color: 'black', margin: '8px'}} variant="contained" onClick={() => setModalOpen(true)}>Add Discipline</Button>

            <DisciplineModal
                currentDiscipline=""
                currentCourse=""
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                endpoint={`${config.rootEndpoint}/ug/courses/`}
                method="POST"
            />

            <div className="courses">
                {
                    courses.map((item) => {
                        const {course, disciplines} = item;
                        return (
                            <CourseWrapper
                                key={course}
                                course = {course}
                                disciplines = {disciplines}
                            />
                        )
                    })
                }
            </div>
        </>
    )
}

export default Courses;