import React from "react";

const CloseModalButton = ({ handleClick }) => {
  return (
    <span
      onClick={handleClick}
      style={{
        height: "32px",
        width: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 16,
        right: 16,
        cursor: "pointer",
        color: "red",
        border: "1px solid red",
        borderRadius: "50%",
      }}
    >
      x
    </span>
  );
};

export default CloseModalButton;
