import React from "react";
import { Formik } from "formik";
import CloseModalButton from "./CloseModalButton";
import TextInputWithValidation from "./TextInputWithValidation";

const EventForm = ({
  initialEventDetails,
  validationSchema,
  closeModal,
  handleSubmit,
}) => {
  return (
    <Formik
      initialValues={initialEventDetails}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        touched,
        errors,
        isValid,
        submitCount,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form
          onSubmit={handleSubmit}
          style={{
            position: "relative",
            padding: "32px 64px",
            backgroundColor: "white",
            alignItems: "center",
            borderRadius: "8px",
            maxHeight: "60vh",
            overflow: "scroll",
          }}
        >
          <h1 style={{ textAlign: "center", marginBottom: "32px" }}>
            New Event
          </h1>

          <div
            className="modal-grid"
            style={{
              marginBottom: "32px",
            }}
          >
            <CloseModalButton handleClick={closeModal} />

            <TextInputWithValidation
              name="event_name"
              type="text"
              label="Event Name"
              placeholder="Event Name"
              value={values.event_name}
              error={touched.event_name && errors.event_name}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextInputWithValidation
              name="slug"
              type="text"
              label="slug"
              placeholder="slug"
              value={values.slug}
              error={touched.slug && errors.slug}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextInputWithValidation
              name="organizer"
              type="text"
              label="Organizer"
              placeholder="Organizer"
              value={values.organizer}
              error={touched.organizer && errors.organizer}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextInputWithValidation
              name="venue"
              type="text"
              label="Venue"
              placeholder="Venue"
              value={values.venue}
              error={touched.venue && errors.venue}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextInputWithValidation
              name="event_date"
              type="date"
              label="Event Date"
              value={values.event_date}
              error={touched.event_date && errors.event_date}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextInputWithValidation
              name="event_time"
              type="time"
              label="Event Time"
              value={values.event_time}
              error={touched.event_time && errors.event_time}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextInputWithValidation
              name="event_image_url"
              type="text"
              label="Image URL"
              placeholder="Image URL"
              value={values.event_image_url}
              error={touched.event_image_url && errors.event_image_url}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextInputWithValidation
              name="category"
              type="text"
              label="Category"
              placeholder="Category"
              value={values.category}
              error={touched.category && errors.category}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextInputWithValidation
              name="registration_link"
              type="text"
              label="Registration Link"
              placeholder="Registration Link"
              value={values.registration_link}
              error={touched.registration_link && errors.registration_link}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div
            style={{
              marginBottom: "32px",
            }}
          >
            <label>Description</label>
            <textarea
              name="description"
              placeholder="Description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={10}
              style={{ width: "100%", marginTop: "8px" }}
            />

            {touched.description && errors.description && (
              <p style={{ color: "red", padding: "8px 0px" }}>
                {errors.description}
              </p>
            )}
          </div>

          {submitCount > 0 && !isValid && (
            <div style={{ color: "red", marginBottom: "16px" }}>
              Kindly fill all the required details
            </div>
          )}

          <button
            disabled={isSubmitting}
            type="submit"
            style={{
              backgroundColor: "#232020",
              color: "white",
            }}
          >
            Create
          </button>
        </form>
      )}
    </Formik>
  );
};

export default EventForm;
