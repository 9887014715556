import React from 'react'

const Modal = ({ children }) => {
  return (
    <div 
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: "0px",
          right: "0px",
          top: "0px",
          bottom: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.4)",
          padding: "32px"
        }}
    >
        {children}
    </div>
  )
}

export default Modal;