import { CircularProgress, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Content from '../../../layouts/Content/Content'
import { useSearchParams } from 'react-router-dom'
import config from '../../../../config.js'
import { toast } from 'react-toastify'
import { fetchSyllabusDetails, updateSyllabusLink, addSyllabusLink } from '../../../../services';
import SubjectHeader from '../../../layouts/Header.jsx'
import useAuth from '../../../../hooks/useAuth.js'

const SyllabusForm = ({ link, syllabusId, disciplineId }) => {
    const [newLink, setNewLink] = useState(link || "");

    const handleSubmit = async () => {
        try {
            const url = `${config.rootEndpoint}/optionals/syllabus/`;

            if(link) {
                await updateSyllabusLink(url, syllabusId, newLink);
                window.location.reload();
            }
            else {
                await addSyllabusLink(url, disciplineId, newLink);
                window.location.reload();
            }
            
        } catch (error) {
            const message = error.response?.data?.message || "Something went wrong";
            toast.error(message);
        }
    }

    return (
        <div className="element form">
            <input
                value = {newLink}
                onChange={(e) => setNewLink(e.target.value)}
                placeholder="Syllabus link"
            />

            <a href = {newLink} target="blank" className="btn-secondary">View</a>
        
            <button 
                className="btn-primary" 
                type="submit" 
                onClick = {handleSubmit}
            >
                {link ? "Update syllabus link" : "Add syllabus link"}
            </button>
        </div>
    )
}

const Syllabus = () => {
    useAuth();

    const [syllabusDetails, setSyllabusDetails] = useState({});
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getSyllabusDetails();
    }, []);

    const getSyllabusDetails = async () => {
        try {
            const disciplineId = searchParams.get('id');
            const url = `${config.rootEndpoint}/optionals/syllabus/?id=${disciplineId}`;
            const data = await fetchSyllabusDetails(url);
            setSyllabusDetails(data);

        } catch (error) {            
            const message = error.response?.data?.message || "Something went wrong";
            toast.error(message);

        } finally {
            setLoading(false);
        }
    }

    if(loading) {
        return <CircularProgress sx={{ color: "white" }} />
    }

    return (
        <div>
            <SubjectHeader heading={`${searchParams.get('name')} Syllabus`} />

            <Stack rowGap={4}>
                <Content
                    contentDetails={syllabusDetails.content}
                    category='syllabus'
                    type='optionals'
                />

                <SyllabusForm 
                    link={syllabusDetails.syllabus?.link}
                    disciplineId={searchParams.get('id')}
                    syllabusId={syllabusDetails.syllabus?.syllabusID}
                />

            </Stack>
        </div>
  )
}

export default Syllabus