import React from "react";

const EventDetails = ({ event }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        padding: "32px",
      }}
    >
      <div className="border-outline">
        <h3>Event Name</h3>
        <p>{event.event_name}</p>
      </div>

      <div className="border-outline">
        <h3>Description</h3>
        <p>{event.description}</p>
      </div>

      <div className="border-outline">
        <h3>Venue</h3>
        <p>{event.venue}</p>
      </div>

      <div className="border-outline">
        <h3>Date</h3>
        <p>{event.event_date}</p>
      </div>

      <div className="border-outline">
        <h3>Time</h3>
        <p>{event.event_time}</p>
      </div>

      <div className="border-outline">
        <h3>Organizer</h3>
        <p>{event.organizer}</p>
      </div>

      {event.event_image_url && (
        <div className="border-outline">
          <h3>Image</h3>
          <img
            style={{ width: "auto", maxWidth: "100%", maxHeight: "320px" }}
            src={event.event_image_url}
            alt={event.name}
            title={event.name}
          />
        </div>
      )}

      {event.category && (
        <div className="border-outline">
          <h3>Category</h3>
          <p>{event.category}</p>
        </div>
      )}

      {event.registration_link && (
        <div className="border-outline">
          <h3>Registration link</h3>
          <a style={{ color: "blue" }} href={event.registration_link}>
            {event.registration_link}
          </a>
        </div>
      )}
    </div>
  );
};

export default EventDetails;
