import React, { useState } from "react";
import * as Yup from "yup";
import Modal from "./Modal.jsx";
import EventForm from "./EventForm.jsx";

const initialEventDetails = {
  event_name: "",
  slug: "",
  event_date: "",
  event_time: "",
  venue: "",
  description: "",
  organizer: "",
  event_image_url: "",
  category: "",
  registration_link: "",
};

const validationSchema = Yup.object({
  event_name: Yup.string().required("Event name is required"),
  slug: Yup.string()
    .matches(/^\S*$/, "slug should not contain whitespace")
    .required("slug is required"),
  event_date: Yup.string().required("Event date is required"),
  event_time: Yup.string().required("Event time is required"),
  venue: Yup.string().required("Venue is required"),
  description: Yup.string().required("Description is required"),
  organizer: Yup.string().required("Organizer is required"),
});

const EventModal = ({ createEvent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    createEvent(values)
      .then(() => closeModal())
      .catch(() => setSubmitting(false));
  };

  return (
    <>
      <button onClick={openModal}>Add Event</button>

      {isOpen && (
        <Modal>
          <EventForm
            initialEventDetails={initialEventDetails}
            validationSchema={validationSchema}
            closeModal={closeModal}
            handleSubmit={handleSubmit}
          />
        </Modal>
      )}
    </>
  );
};

export default EventModal;
