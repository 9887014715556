import React from "react";
import { Link } from "react-router-dom";

const EventCard = ({ event, deleteEvent }) => {
  const { slug, event_name, event_date, event_time, venue } = event;

  return (
    <div
      style={{
        width: "fit-content",
        maxWidth: "320px",
        maxHeight: "240px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "16px",
        border: "1px solid lightgray",
        borderRadius: "8px",
        padding: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <p className="truncate-multiple">
          <span style={{ fontWeight: "bold" }}>Event name:</span> {event_name}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Venue:</span> {venue}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Date:</span> {event_date}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Time:</span> {event_time}
        </p>
      </div>

      <div style={{ display: "flex", gap: "8px" }}>
        <Link to={`/events/details?event=${event.event_id}`}>
          <button style={{ padding: "12px", backgroundColor: "#40ad40" }}>
            View Event
          </button>
        </Link>

        <button
          style={{
            padding: "12px",
            backgroundColor: "#ec2d2d",
            color: "white",
          }}
          onClick={() => deleteEvent(slug)}
        >
          Delete Event
        </button>
      </div>
    </div>
  );
};

export default EventCard;
