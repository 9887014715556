import axios from "axios";
import { useEffect, useState } from "react";

import Loader from "../../../layouts/Loader/Loader";
import config from "../../../../config";
import { Link, useSearchParams } from "react-router-dom";
import Fallback from "../../../layouts/Fallback/Fallback";
import { Box, Button, Stack, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useAuth from "../../../../hooks/useAuth.js";


const BlogDetails = () => {
    useAuth();
    const [blogDetails, setBlogDetails] = useState({});
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);

    const { title, description, content, category, author, tags, coverImage } = blogDetails;
    const tagList = tags ? tags.split(", ") : [];
    const blogId = searchParams.get('id');

    const fetchBlogDetails = async () => {
        try {
            const response = await axios.get(`${config.rootEndpoint}/blogs/?id=${blogId}`);
            return response.data;
            
        } catch(error) {
            return {};
        }
    }

    useEffect(() => {
        const getBlogDetails = async () => {
            const data = await fetchBlogDetails();
            setBlogDetails(data);
            setLoading(false);
        }

        getBlogDetails();
    }, []);

    if(loading) {
        return <Loader text="Loading Blog"/>
    }

    else if(!blogDetails || Object.keys(blogDetails).length === 0) {
        return <Fallback text="Blog not found"/>
    }


    return (
        <>
            <Stack flexDirection="row" justifyContent="space-between" padding={2} flexWrap="wrap" gap={2}>
                <Link to={`/blogs/all`}>
                    <Button
                        variant="contained"
                        startIcon={<ArrowBackIcon />}
                    >
                        All Blogs
                    </Button>
                </Link>

                <Link to={`./edit?id=${blogId}`}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        startIcon={<EditIcon />}
                    >
                        Edit
                    </Button>
                </Link>
            </Stack>

            <Stack rowGap={2} padding={2}>
                <div>
                    <Typography variant="h4" gutterBottom>
                        Title
                    </Typography>

                    <Typography 
                        sx={{border: '1px solid lightgray', borderRadius: '8px', padding: '16px'}}
                        gutterBottom
                    >
                        {title}
                    </Typography>
                </div>

                <div>
                    <Typography variant="h4" gutterBottom>
                        Description
                    </Typography>

                    <Typography 
                        sx={{border: '1px solid lightgray', borderRadius: '8px', padding: '16px'}}
                        gutterBottom
                    >
                        {description}
                    </Typography>
                </div>

                <div>
                    <Typography variant="h4" gutterBottom>
                        Cover Image
                    </Typography>

                    <Stack>
                        <Typography 
                            sx={{border: '1px solid lightgray', borderRadius: '8px', padding: '16px'}}
                            gutterBottom
                        >
                            {coverImage}

                        </Typography>

                        <Link to={coverImage} target="_blank">
                            <Button variant="outlined" sx={{height: "100%"}}>
                                View
                            </Button>
                        </Link>
                    </Stack>
                </div>

                <div>
                    <Typography variant="h4" gutterBottom>
                        Content
                    </Typography>

                    <Box
                        sx={{border: '1px solid lightgray', borderRadius: '8px', padding: '16px'}}
                        dangerouslySetInnerHTML={{__html: content}}
                    >
                    </Box>
                </div>

                <div>
                    <Typography variant="h4" gutterBottom>
                        Category
                    </Typography>

                    <Typography
                        sx={{border: '1px solid lightgray', borderRadius: '8px', padding: '16px'}}
                        gutterBottom
                    >
                        {category}
                    </Typography>
                </div>

                <div>
                    <Typography variant="h4" gutterBottom>
                        Author
                    </Typography>

                    <Typography
                        sx={{border: '1px solid lightgray', borderRadius: '8px', padding: '16px'}} 
                        gutterBottom
                    >
                        {author}
                    </Typography>
                </div>

                <div>
                    <Typography variant="h4" gutterBottom>
                        Tags
                    </Typography>

                    <Stack
                        sx={{border: '1px solid lightgray', borderRadius: '8px', padding: '16px'}}
                        flexDirection="row" 
                        flexWrap="wrap" 
                        gap={1}
                    >
                        {tagList && tagList.map((tag, index) => (
                            <Typography 
                                sx={{padding: '8px', backgroundColor: 'cornflowerblue', borderRadius: '8px'}}
                                color="white"
                                key={index}
                            >
                                {tag}
                            </Typography>
                        ))}
                    </Stack>
                </div>
            </Stack>
        </>
    )
}

export default BlogDetails;