import TextInput from "./TextInput.jsx";

const TextInputWithValidation = ({
  name,
  type,
  label,
  placeholder,
  style,
  value,
  error,
  onChange,
  onBlur,
}) => {
  return (
    <div>
      <TextInput
        name={name}
        type={type}
        label={label}
        placeholder={placeholder}
        style={style}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />

      {error && <p style={{ color: "red", padding: "8px 0px" }}>{error}</p>}
    </div>
  );
};

export default TextInputWithValidation;
