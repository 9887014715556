import { useState } from "react";

const PyqEdit = ({ paperID, year, paperLink, type, updatePyq }) => {
    const [newYear, setNewYear] = useState(year);
    const [newType, setNewType] = useState(type);
    const [newPaperLink, setNewPaperLink] = useState(paperLink);

    const handleInputChange = (value, updaterFunction) => {
        updaterFunction(value);
    }

    return (
        <div className="form element">
            <input type = "number" value={newYear} onChange={(e) => handleInputChange(e.target.value, setNewYear)}/>

            <select value={newType} onChange={(e) => handleInputChange(e.target.value, setNewType)}>
                <option disabled>--Type--</option>
                <option value="solved">Solved</option>
                <option value="unsolved">Unsolved</option>
            </select>

            <input className="link" type = "text" value={newPaperLink} onChange={(e) => handleInputChange(e.target.value, setNewPaperLink)}/>

            <a style={{padding: "16px", borderRadius: "8px"}} className="btn-secondary" href={newPaperLink} target="blank">View</a>

            <button 
                className="btn-primary" 
                onClick={() => updatePyq(paperID, newYear, newType, newPaperLink)}
            >
                Save
            </button>

        </div>
    )
}

const PyqView = ({ year, paperLink, type, setEditing, paperID, deletePyq }) => {

    return (
        <div className="form element">
            <input type = "text" value={year} disabled/>
            <select value={type} disabled>
                <option value="solved">Solved</option>
                <option value="unsolved">Unsolved</option>
            </select>

            <input className="link" type = "text" value={paperLink} disabled/>

            <a style={{padding: "16px", borderRadius: "8px"}} className="btn-secondary" href={paperLink} target="blank">View</a>

            <button className="btn-secondary" onClick={() => setEditing(true)}>Edit</button>

            <button className="btn-red" onClick={() => deletePyq(paperID)}>Delete</button>
        </div>
    )
}

const PyqCurrent = ({ year, paperLink, type, paperID, updatePyq, deletePyq }) => {
    const [editing, setEditing] = useState(false);

    return (
        <>
            {editing ? (
                <PyqEdit 
                    year={year}
                    paperLink={paperLink}
                    type={type}
                    paperID={paperID}
                    updatePyq={updatePyq}
                />
            ) : (
                <PyqView 
                    year={year}
                    paperLink={paperLink}
                    type={type}
                    paperID={paperID}
                    setEditing={setEditing}
                    deletePyq={deletePyq}
                />
            )}
        </>
    )
}

const PyqCurrentWrapper = ({ pyq, updatePyq, deletePyq }) => {
    return (
        <div className="pyqCurrent">
            {pyq && pyq.map((item) => {
                return (
                    <PyqCurrent 
                        key={item.paperID}
                        year={item.year}
                        paperLink={item.paperLink || item.link}
                        type={item.type}
                        paperID={item.paperID}
                        updatePyq={updatePyq}
                        deletePyq={deletePyq}
                    />
                )
            })}
        </div>
    )
}

const PyqNew = ({ createPyq }) => {
    const [adding, setAdding] = useState(false);
    const [year, setYear] = useState("");
    const [type, setType] = useState("solved");
    const [paperLink, setPaperLink] = useState("");

    return (
        <div className="form element">
            {adding ? (
                <>
                    <input placeholder="Year" type = "number" value={year} onChange={(e) => setYear(e.target.value)}/>
                    
                    <select value={type} onChange={(e) => setType(e.target.value)}>
                        <option disabled>--type--</option>
                        <option value="solved">Solved</option>
                        <option value="unsolved">Unsolved</option>
                    </select>

                    <input placeholder="Link" className="link" type = "text" value={paperLink} onChange={(e) => setPaperLink(e.target.value)}/>

                    <a style={{padding: "16px", borderRadius: "8px"}} className="btn-secondary" href={paperLink} target="blank">View</a>

                    <button 
                        className="btn-primary" 
                        onClick={() => createPyq(year, paperLink, type)}
                    >
                        Save
                    </button>
                </>
            ) : (
                <>
                    <button className="btn-secondary" onClick={() => setAdding(true)}>Add Pyq</button>
                </>
            )}
        </div>
    )
}

const PyqWrapper = ({ pyq, createPyq, updatePyq, deletePyq }) => {
  return (
    <div className="pyqWrapper">
        <h1 className="headline element">PYQ</h1>

        <PyqCurrentWrapper
            pyq={pyq}
            updatePyq={updatePyq}
            deletePyq={deletePyq}
        />

        <PyqNew 
            createPyq={createPyq}
        />
    </div>
  )
}

export default PyqWrapper;